<template>
  <v-container class="px-5">
    <p class="text-h4 titulo-card">Dashboard</p>
    <v-row class="my-4">
      <v-col>
        <v-card elevation="4" class="px-4">
          <v-row>
            <v-col cols="9" class="pb-0">
              <p class="text-h6 mb-1">School District</p>
              <p class="text-h5 mb-1">{{ schoolDistrict }}</p>
            </v-col>
            <v-col class="my-1 pa-0 text-center" cols="3">
              <img src="../../../assets/admin/ICONO1.png" class="imagen-info" />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col>
        <v-card elevation="4" class="px-4">
          <v-row>
            <v-col cols="9" class="pb-0">
              <p class="text-h6 mb-1">School</p>
              <p class="text-h5 mb-1">{{ school }}</p>
            </v-col>
            <v-col class="my-1 pa-0 text-center" cols="3">
              <img src="../../../assets/admin/ICONO2.png" class="imagen-info" />
            </v-col>
          </v-row>
        </v-card>
      </v-col>      
    </v-row>
    <v-row class="my-5">      
      <v-col>
        <v-card elevation="2" class="px-4" color="#72c883" flat dark>
          <v-row>
            <v-col cols="8">
              <p class="text-h5 text-center">Lee Lee Teachers</p>
              <p class="text-h3 text-center">{{ teachers }}</p>              
            </v-col>
            <v-col class="pa-2 text-center my-auto py-auto" cols="4">
              <img src="../../../assets/admin/groups.png" class="imagen-info" />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col>
        <v-card elevation="2" class="px-4" color="#fcb255" flat dark>
          <v-row>
            <v-col cols="8">
              <p class="text-h5 text-center">Lee Lee Students</p>
              <p class="text-h3 text-center">{{ students }}</p>              
            </v-col>
            <v-col class="pa-2 text-center my-auto py-auto" cols="4">
              <img
                src="../../../assets/admin/students.png"
                class="imagen-info"
              />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>    
    <v-card elevation="2" class="my-5">
      <v-row>
        <v-col class="text-center">
          <v-row>
            <v-col>
              <h3>PK-2 licenses</h3>
              <strong>{{ Pk2AvailableStudentLicences }} available</strong><br>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <doughnut :chart-data="CharPk2" :options="CharPk2Options" :height="250"/>
            </v-col>
          </v-row>
        </v-col>
        <v-divider vertical></v-divider>
        <v-col class="text-center">
          <v-row>
            <v-col>
              <h3>3-5 licenses</h3>
              <strong>{{ G35AvailableStudentLicences }} available</strong><br>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <doughnut :chart-data="CharG35" :options="CharG35Options" :height="250"/>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
    <v-divider></v-divider>
    <v-card elevation="2">
      <v-row>
        <v-col class="text-center">
          <h4>Usage Time Record</h4>
          <strong>{{convertSecondsToHours(Pk2TotalMonthlyTimeUse)}} hours</strong> 
        </v-col>
        <v-divider vertical></v-divider>
        <v-col class="text-center">
          <h4>Usage Time Record</h4>
          <strong>{{convertSecondsToHours(G35TotalMonthlyTimeUse)}} hours</strong> 
        </v-col>
      </v-row>
    </v-card>
    <v-divider></v-divider>    
  </v-container>
</template>
<script>
import { db, functions, auth } from '../../../plugins/firebase'
//import firebase from "firebase";
import Doughnut from '../../../components/Doughnut';

Chart.pluginService.register({
  id: 'textCenter',
  beforeDraw: function(chart) {
    if (chart.config.options.elements && chart.config.options.elements.center) {
      var ctx = chart.ctx;
      var centerConfig = chart.config.options.elements.center;
      var fontStyle = centerConfig.fontStyle || 'Arial';
      var txt = centerConfig.text;
      var color = centerConfig.color || '#000';
      var sidePadding = centerConfig.sidePadding || 20;
      var sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2);

      ctx.font = "30px " + fontStyle;

      var stringWidth = ctx.measureText(txt).width;
      var elementWidth = chart.innerRadius * 2 - sidePaddingCalculated;

      var widthRatio = elementWidth / stringWidth;
      var newFontSize = Math.floor(30 * widthRatio);
      var elementHeight = chart.innerRadius * 2;

      var fontSizeToUse = Math.min(newFontSize, elementHeight);

      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      var centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
      var centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;

      // Split the text into two lines
      var _text=txt.split(' ');
      var text1 = _text[0];
      var text2 = _text[1]+' '+_text[2] || '';

      // Draw the first line of text
      ctx.font = 'bold ' + (fontSizeToUse/0.6) + 'px ' + fontStyle;
      ctx.fillText(text1, centerX, centerY - (fontSizeToUse / 2));

      // Draw the second line of text
      ctx.font = (fontSizeToUse * 0.6) + 'px ' + fontStyle;
      ctx.fillText(text2, centerX, centerY + (fontSizeToUse / 2));
    }
  }
});

export default {
  components: {
    Doughnut
  },
  data() {
    return {
      school: "",
      schoolDistrict: "",
      teachers: 0,
      students: 0,
      studentsDataRef: '',
      Pk2ClassLicenses: 0,
      G35ClassLicenses: 0,
      Pk2AssignedLicenses: 0,
      G35AssignedLicenses: 0,
      Pk2TotalStudents: 0,
      G35TotalStudents: 0,
      Pk2AvailableStudentLicences: 0,
      G35AvailableStudentLicences: 0,
      Pk2RemainingLicenses: 0,
      G35RemainingLicenses: 0,
      Pk2TotalMonthlyTimeUse: 0,
      G35TotalMonthlyTimeUse: 0,
      gradeList: ['Pre-School', 'Kindergarten', '1st Grade', '2nd Grade', '3rd Grade', '4th Grade', '5th Grade'],           
      CharAciertos: {},
      CharPk2: {},
      CharG35: {},
      CharPk2Options: {
        responsive: true,
        hoverBorderWidth: 20,
        borderWidth: "10px", 
        hoverBackgroundColor: "red",
        cutoutPercentage: 70,
        elements: {
          center: {
            text: '',
            color: '#000',
            fontStyle: 'Arial',
            sidePadding: 20
          }
        }
      },
      CharG35Options: {
        responsive: true,
        hoverBorderWidth: 20,
        borderWidth: "10px", 
        hoverBackgroundColor: "red",
        cutoutPercentage: 70,
        elements: {
          center: {
            text: '',
            color: '#000',
            fontStyle: 'Arial',
            sidePadding: 20
          }
        }
      }
    };
  },
  created() {},
  methods: {
    drawDoughnut(Pk2Length, G35Length, Pk2Variable, G35Variable) {
      // primer grafico      
      Pk2Variable = (Pk2Variable / Pk2Length).toFixed(2);
      Pk2Variable = (Pk2Variable * 100);
      this.CharPk2 = {
        labels: ['Assigned '+Pk2Variable, 'Remaining'],
        datasets: [{
          label: 'Assigned',
          data: [Pk2Variable, (100 - Pk2Variable)],
          backgroundColor: ['#6D9EEB', '#C9DAF8']
        }]
      };
      this.CharPk2Options.elements.center.text = this.Pk2RemainingLicenses + ' Remaining licenses';

      //Segundo grafico
      G35Variable = (G35Variable / G35Length).toFixed(2);
      G35Variable = (G35Variable * 100);
      this.CharG35 = {
        labels: ['Assigned '+G35Variable, 'Remaining'],
        datasets: [{
          label: 'Assigned',
          data: [G35Variable, (100 - G35Variable)],
          backgroundColor: ['#6D9EEB', '#C9DAF8']
        }]
      };
      this.CharG35Options.elements.center.text = this.G35RemainingLicenses + ' Remaining licenses';
    },
    findStudentPosition(book, student) {
      for (let p = 0; p < book.length; p++) {
        if (book[p].Students.hasOwnProperty(student)) {
          return p;
        }
      }
      return -1;
    },
    async updateTotalTimeUse(){
      await db.doc(this.studentsDataRef.path).set({
        TotalTimeUseL1: this.Pk2TotalMonthlyTimeUse,
        TotalTimeUseL2: this.G35TotalMonthlyTimeUse,
        LastDateTotalTiemUse: new Date().toISOString().slice(0,10)
      }, {merge: true});       
    },
    async updateStudentsData(studentBook, studentBookIndex){
      const updatePromises = studentBook.map((page, index) => 
        db.doc(this.studentsDataRef.path).collection('StudentsData').doc(studentBookIndex[index]).set(page)
      );

      await Promise.all(updatePromises);
    },
    async getDataSchool() {
      const id = localStorage.getItem('uid');
      const role = localStorage.getItem('role');
      const user = { id: id, role: role };

      // Obtener datos del administrador
      const info = await db.collection('SchoolAdmin').doc(id).get();
      const infoAdmin = info.data();
      this.studentsDataRef = infoAdmin.Reference;
      this.schoolDistrict = infoAdmin.SchoolDistrict;
      this.school = infoAdmin.School;

      // Obtener datos de la escuela
      const schoolDataPromise = db.doc(this.studentsDataRef.path).get();
      const studentsDataPromise = db.doc(this.studentsDataRef.path).collection('StudentsData').get();

      const [schoolDataSnapshot, studentsDataSnapshot] = await Promise.all([schoolDataPromise, studentsDataPromise]);

      const getSchoolData = schoolDataSnapshot.data();
      this.Pk2RemainingLicenses = getSchoolData.L1RemainingLicenses;
      this.G35RemainingLicenses = getSchoolData.L2RemainingLicenses;
      const teacherData = Object.keys(getSchoolData.SchoolTeachers);
      this.teachers = teacherData.length;

      // Procesar datos de estudiantes
      const studentBookIndex = [];
      const studentBook = [];
      studentsDataSnapshot.forEach((doc) => {
        studentBookIndex.push(doc.id);
        studentBook.push(doc.data());
      });

      // Obtener datos de los profesores y aulas en paralelo
      const teacherPromises = teacherData.map(idt => db.collection('SchoolTeacher').doc(idt).get());
      const teacherSnapshots = await Promise.all(teacherPromises);

      // Procesar datos de cada profesor y sus aulas
      const processTeacherData = teacherSnapshots.map(async (teacherSnapshot) => {
        const TempTeacherData = teacherSnapshot.data();
        const classroom = TempTeacherData.Classrooms;
        const clasesNames = Object.keys(classroom);

        const classPromises = clasesNames.map(ClassroomSelected => 
          db.collection('SchoolTeacher').doc(teacherSnapshot.id).collection('Classrooms').doc(ClassroomSelected).get()
        );

        const classSnapshots = await Promise.all(classPromises);

        classSnapshots.forEach(TempClassSnapshot => {
          const TempClassData = TempClassSnapshot.data();
          const studentData = TempClassData.MainGroup;
          const students = Object.keys(studentData);

          this.students += students.length;

          const classroomSelectedData = classroom[TempClassSnapshot.id];

          const isPk2 = classroomSelectedData.hasOwnProperty('AssignedLicenses') && this.gradeList.slice(0, 4).includes(TempClassData.Grade);
          const isG35 = classroomSelectedData.hasOwnProperty('AssignedLicenses') && this.gradeList.includes(TempClassData.Grade, 4);

          if (isPk2 || isG35) {
            const category = isPk2 ? 'Pk2' : 'G35';
            this[`${category}AssignedLicenses`] += classroomSelectedData.AssignedLicenses;
            this[`${category}ClassLicenses`] += classroomSelectedData.ClassLicenses;
            this[`${category}TotalStudents`] += classroomSelectedData.TotalStudents;

            students.forEach(student => {            
              const page = this.findStudentPosition(studentBook, student);

              studentBook[page].Students[student].MonthlyTimeUse = studentData[student].hasOwnProperty('MonthlyTimeUse') && studentData[student].MonthlyTimeUse != null?studentData[student].MonthlyTimeUse:0;
              studentBook[page].Students[student].ClassroomName = classroomSelectedData.Name || '';
              studentBook[page].Students[student].TeacherFullName = `${TempTeacherData.Name} ${TempTeacherData.LastName}`;
              studentBook[page].Students[student].AutoIdClassroom = TempClassSnapshot.id;
              if (studentData[student].hasOwnProperty('MonthlyTimeUse') && studentData[student].MonthlyTimeUse != null) {
                const idsTime = Object.keys(studentData[student].MonthlyTimeUse) || [];
                idsTime.forEach(element => {
                  this[`${category}TotalMonthlyTimeUse`] += studentData[student].MonthlyTimeUse[element];
                });
              }              
            });
          }
        });
      });

      await Promise.all(processTeacherData);

      this.Pk2AvailableStudentLicences = this.Pk2ClassLicenses - this.Pk2AssignedLicenses;
      this.G35AvailableStudentLicences = this.G35ClassLicenses - this.G35AssignedLicenses;

      console.log("Resultado de Datos");
      console.log(studentBook);

      // Comprobar y actualizar si es necesario
      let now = new Date();
      now.setHours(0, 0, 0, 0);
      let memory = localStorage.getItem('update');

      if (memory != null) {
        let memoryDate = new Date(memory);
        if (now.getTime() > memoryDate.getTime()) {
          console.log("Guardamos los datos es otro dia");
          this.updateTotalTimeUse();
          this.updateStudentsData(studentBook, studentBookIndex);
          localStorage.setItem('update', now);
        } else {
          console.log("Es el mismo dia");
        }
      } else {
        console.log("Memoria de tiempo vacia, como no existe actualizamos");
        this.updateTotalTimeUse();
        this.updateStudentsData(studentBook, studentBookIndex);
        localStorage.setItem('update', now);
      }

      this.drawDoughnut(this.Pk2ClassLicenses, this.G35ClassLicenses, this.Pk2AssignedLicenses, this.G35AssignedLicenses);
    },
    convertSecondsToHours(seconds) {
      const hours = Math.trunc(seconds / 3600);
      return hours;
    }
  },
  mounted() {    
    this.getDataSchool();    
  },
};
</script>
<style>
.school-icon {
  background-color: #1f6f1f;
}
.end-date-icon {
  background-color: #da4545;
}
.icons-info {
  font-size: 100px !important;
}
.imagen-info {
  width: 100px;
}
.col-with-border {
  position: relative; /* Necesario para el pseudo-elemento */
}

.col-with-border::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 1px;
  height: 100%;
  background-color: #000; /* Color de la línea divisoria */
}
@media only screen and (max-width: 1200px) {
  .icons-info {
    font-size: 80px !important;
  }
  .imagen-info {
    width: 80px;
  }
}
@media only screen and (max-width: 992px) {
  .icons-info {
    font-size: 65px !important;
  }
  .imagen-info {
    width: 65px;
  }
}
@media only screen and (max-width: 768px) {
  .icons-info {
    font-size: 50px !important;
  }
  .imagen-info {
    width: 50px;
  }
}
</style>